import { UserType, useUpdateUserMutation } from "@/graphql/types";
import gql from "graphql-tag";
import useParentQuery from "./useParentQuery";

gql`
  mutation UpdateUser($input: UpdateUserInputType!) {
    updateUser(input: $input) {
      ...UserParts
      roles {
        roleId
        organizationId
        applicationId
        roleName
        organizationName
        applicationName
      }
    }
  }
`;

export default function (
  organizationId: string,
  userId: string,
  user: UserType,
  fullInput = true
) {
  const mutation = useUpdateUserMutation({});

  function call() {
    mutation.mutate(
      {
        input: fullInput
          ? {
              userId: userId,
              phone: user.phone,
              enabled: user.enabled,
              approvalState: user.approvalState,
              email: user.email,
            }
          : {
              userId: userId,
              phone: user.phone,
              enabled: user.enabled,
              approvalState: user.approvalState,
              email: user.email,
            },
      },
      {
        update: (cache, { data: updatedUser }) => {
          const parentQuery = useParentQuery(organizationId);
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              organization: {
                ...cachedData?.organization,
                users: {
                  total: cachedData?.organization?.users?.total,
                  items: cachedData?.organization?.users?.items?.map(
                    (element) =>
                      element?.userId == updatedUser?.updateUser?.userId
                        ? { ...updatedUser?.updateUser }
                        : element
                  ),
                },
              },
            },
          });
        },
      }
    );
  }

  return {
    call,
    ...mutation,
  };
}
