
import Page from "@/components/Page.vue";
import AppHeader from "@/components/AppHeader.vue";
import useUser from "@/composables/useUser";
import { useGetUserQuery, UserType } from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import useError from "@/composables/useError";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { computed, reactive, toRef, watch } from "vue";
import useUpdateUser from "./User/mutations/useUpdateUser";
import Error from "@/components/Error.vue";
import useToastService from "@/composables/useToastService";
import VDButton from "@/components/VDButton.vue";

export default {
  components: { Page, AppHeader, Error, VDButton },
  setup() {
    const { user: profile } = useUser();
    const toastService = useToastService();
    const { result, loading, error } = useGetUserQuery(() => ({
      id: profile.value?.userId ?? "",
    }));

    const user = reactive<UserType>({ roles: [] });
    const initialUser = reactive<UserType>({});

    const v$ = useVuelidate(useValidations(), {
      firstname: toRef(user, "firstname"),
      lastname: toRef(user, "lastname"),
    });

    const updateUserMutation = useUpdateUser(
      profile.value?.organizationId,
      profile.value?.userId ?? "",
      user,
      false
    );

    const hasChanges = computed(() => {
      return (
        user.firstname !== initialUser.firstname ||
        user.lastname !== initialUser.lastname ||
        user.phone !== initialUser.phone
      );
    });

    watch(
      () => useResult(result).value,
      (newValue) => {
        Object.assign(user, newValue);
        Object.assign(initialUser, newValue);
      },
      { immediate: true }
    );

    updateUserMutation.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Gemt",
        message: `Dine detajler er blevet opdateret`,
      });
    });

    return {
      user,
      error: useError(error),
      loading,
      v$,
      save: () => updateUserMutation.call(),
      actionButtonLoading: computed(() => updateUserMutation.loading.value),
      hasChanges,
    };
  },
};

function useValidations() {
  return {
    firstname: { required },
    lastname: { required },
  };
}
